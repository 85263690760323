// import React from "react"
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { is_white_mark } from "../../constants/defaultValues";


import {
  USER_GET_LIST,
  USER_CHANGE_STATE,
  LOGIN_USER,
  CHAT_LOGIN_USER,
  GET_CHATROOM_ID,
  REGISTER_USER,
  LOGOUT_USER,
  // FORGOT_PASSWORD,
  // //RESET_PASSWORD,
  // EDIT_USER,
  GET_COMPANY,
  USER_DELETE,
  USER_GET_BY_FILTER
} from './actionTypes';

import {
  LoginUserSuccess,
  LoginUserError,

  chatLoginUserSuccess,
  chatLoginUserError,

  getRoomIdSuccess,
  getRoomIdError,

  registerUserSuccess,
  registerUserError,
  // forgotPasswordSuccess,
  // forgotPasswordError,
  // resetPasswordSuccess,
  // resetPasswordError,

  // editUserSuccess,
  // editUserError,

  getUsersListSuccess,
  getUsersListError,


  getUserByFilterSuccess,
  getUserByFilterError,

  changeUserStateSuccess,
  changeUserStateError,

  deleteOneUserSuccess,
  deleteOneUserError,

  getCompanySuccess,
  getCompanyError

} from './actions';
import configAxios from '../../axiosConfig';
import { axiosGlobalConfigs } from '../../helpers/axiosConfig';
const axiosApi = axios;
// CHANGE USER STATE

const changeUserStateRequest = async (payload) => {
  return await axiosApi.patch(api.users + `/activate/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* changeUserState({ payload }) {
  try {
    const ret = yield call(changeUserStateRequest, payload);

    if (ret && ret.status === 200)
      yield put(changeUserStateSuccess({ user: ret.data.user || [], ref_code: ret.data.ref_code }));
    const { history } = payload;

    if (history) history.push('/admin/users/list')
    else yield put(changeUserStateError({ message: "Error" }))

  } catch (error) {
    yield put(changeUserStateError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchchangeUserState() {
  yield takeEvery(USER_CHANGE_STATE, changeUserState);
}


// EDIT USER  



// const editUserRequest = async (payload) => {

//   return await axiosApi.patch(api.users + `/edit-one/${payload._id}`, payload.data)
//     .then(resp => resp)
//     .catch(error => { return (error && error.response) ? error.response : error })
// };




// const editUserRequest = (payload, callback, callBackError) => {

//     try {
//       return axiosApi.patch(api.users + `/edit-one/${payload._id}`, payload.data)
//         .then(resp => {
//          callback(resp);
//         })
//         .catch(err => {
//           callBackError(err);
//         })
//     } catch (err) {
//     }

// };


// function* editUser({ payload }) {

//   try {
//     const ret = yield call(editUserRequest, payload);

//     if (ret && ret.status === 200) {

//       const { history } = payload;


//       if (history) history.push('/account')
//       const old_user = localStorage.getItem('odesco_user')
//       const user = { ...ret.data.user, token: JSON.parse(old_user).token }
//       // console.log('USER', user);

//       //console.log("old_user : ",JSON.parse(old_user).token);

//       localStorage.setItem('odesco_user', JSON.stringify(user));
//       yield put(editUserSuccess({ user: ret.data.user }));


//     }

//     else yield put(editUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

//   } catch (error) {

//     yield put(editUserError({ error, message: (error) ? error.message : '' }));
//   }
// }


// export function* watchEditUser() {
//   yield takeEvery(EDIT_USER, editUser);
// }

//GET COMPANY

const getCompanyRequest = async (payload) => {
  return await axiosApi.get(api.users + `/company/get-one`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getCompany({ payload }) {
  try {
    const ret = yield call(getCompanyRequest, payload);

    if (ret && ret.status === 200){
      
      const company = ret.data.company;
      localStorage.setItem('odesco_company', JSON.stringify(company));
      yield put(getCompanySuccess({ company: ret.data.company }));

    }

    else yield put(getCompanyError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getCompanyError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchGetCompany() {
  yield takeEvery(GET_COMPANY, getCompany);
}


//GET LIST
const getUsersListRequest = async (payload) => {
  return await axiosApi.get(api.users + `/list/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getUsersList({ payload }) {
  try {
    const ret = yield call(getUsersListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getUsersListSuccess({ users: ret.data.users || [], count: ret.data.count || 0 }));

    else yield put(getUsersListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getUsersListError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchGetUsersList() {
  yield takeEvery(USER_GET_LIST, getUsersList);
}





export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}





//GET USER BY FILTER
const getUserByFilterRequest = async (payload) => {
  return await axiosApi.post(api.users + `/filters/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getUserByFilter({ payload }) {

  try {
    const ret = yield call(getUserByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getUserByFilterSuccess({ users: ret.data.users, count: ret.data.count }));

    else yield put(getUserByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getUserByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchGetFilter() {
  yield takeEvery(USER_GET_BY_FILTER, getUserByFilter);
}

// const loginWithEmailPasswordAsync = async (email, pass) => {
//   await axiosApi.post(api.users + '/login', { email, pass })
//     .then(authUser => authUser)
//     .catch(error => error);
// }

const loginWithEmailPasswordAsync = async (email, pass,deviceId) => {
  return await axiosApi.post(api.users + '/login', { email, pass, deviceId })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* loginWithEmailPassword({ payload }) {
  const { email, password, deviceId } = payload.user;
  const { history } = payload;

  try {
    const connect = yield call(loginWithEmailPasswordAsync, email, password, deviceId);

    if (connect && connect.status === 200) {
      const token = "Bearer " + connect.data.token;
      const user = connect.data.user;
      const company = connect.data.company;
      localStorage.setItem('odesco_user', JSON.stringify(user));
      localStorage.setItem('odesco_company', JSON.stringify(company));
      localStorage.setItem('authToken', token);
      configAxios(token);
      yield put(LoginUserSuccess({ user, token, company }));
      history.push(user?.admin_roles?.includes("ADMIN") ? "/admin/institutions/list" : '/')
    } else {
      yield put(LoginUserError((connect.data) ? connect.data.msg : "Network Error"));
    }
  } catch (error) {
    // yield put(LoginUserError(error));
  }
}
/******************************************************************************************/
export function* watchChatLoginUser() {
  yield takeEvery(CHAT_LOGIN_USER, chatLoginWithEmailPassword);
}


const chatLoginWithEmailPasswordAsync = async (user, pass) => {
  return await axiosApi.post(api.users + '/rocketLogin', { user, pass })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* chatLoginWithEmailPassword({ payload }) {
  const { user, password } = payload.user;
  // const { history } = payload;

  try {
    const connect = yield call(chatLoginWithEmailPasswordAsync, user, password);

    if (connect && connect.status === 200) {
      const rocket_user = { ...connect.data.rocket_user }
      // console.log('USER', user);


      localStorage.setItem('rocket_user', JSON.stringify(rocket_user));
      yield put(chatLoginUserSuccess(rocket_user));
      return (rocket_user);
    } else {
      yield put(chatLoginUserError((connect.data) ? connect.data.msg : "Network Error"));
      // history.push('/');
    }
  } catch (error) {
    // yield put(LoginUserError(error));
  }
}






export function* watchGetChatroomId() {
  yield takeEvery(GET_CHATROOM_ID, getChatroomId);
}


const getChatroomIdAsync = async (data) => {

  return await axiosApi.post(api.users + '/rocketRoomId', data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* getChatroomId({ payload }) {

  const { partner, username } = payload.data;
  // const { history } = payload.history;
  // let config = {
  //   headers: {
  //     "rocketAuthToken": rocketAuthToken,
  //     "rocketId": rocketId
  //   }
  // }

  try {
    const connect = yield call(getChatroomIdAsync, { username, partner, text: "" });

    if (connect && connect.status === 200) {
      const rocket_room_id = connect.data.roomId;
      // console.log('USER', user);

      // localStorage.setItem('rocket_user', JSON.stringify(rocket_user));
      yield put(getRoomIdSuccess(rocket_room_id));

    } else {
      yield put(getRoomIdError((connect.data) ? connect.data.msg : "Network Error"));
      // history.push('/');
    }
  } catch (error) {
    // yield put(LoginUserError(error));
  }
}
/******************************************************************************************/





export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, webRegister);
}
const webRegisterAsync = async (data) => {

  return await axiosApi.post(api.users + '/signup', data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* webRegister({ payload }) {
  const { history, user } = payload;
  try {
    const register = yield call(webRegisterAsync, user);
    // console.log('=======+++++++++++++++> register', register)

    if (register && register.status === 201) {

      const token = `Bearer ${register.data.token}`
      const user = { ...register.data.user, token }
      localStorage.setItem('odesco_user', JSON.stringify(user));
      localStorage.setItem('authToken', token);

      configAxios(token);

      if (!is_white_mark) {


        yield put(LoginUserSuccess(user));
      }

      // history.push('/');
      yield put(registerUserSuccess(user));
      // history.push('/');
    } else {
      yield put(registerUserError((register.data) ? register.data.msg : "Network Error"));
    }
  } catch (error) {
    console.log('ERR REGISTER', error)

  }
}








// const registerWithEmailPasswordAsync = async (email, password) =>
//   await auth.createUserWithEmailAndPassword(email, password)
//     .then(authUser => authUser)
//     .catch(error => error);

// function* registerWithEmailPassword({ payload }) {
//   const { email, password } = payload.user;
//   const { history } = payload
//   try {
//     const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
//     if (!registerUser.message) {
//       // const user = {...connect.data.user, token: "Bearer "+connect.data.token}

//       localStorage.setItem('odesco_user', JSON.stringify(registerUser.user));
//       yield put(registerUserSuccess(registerUser));
//       history.push('/')
//     } else {
//       yield put(registerUserError(registerUser.message));

//     }
//   } catch (error) {
//     yield put(registerUserError(error));
//   }
// }



export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async ({history,deviceId}) => {
  axios.post(api.users + "/logout",{deviceId}).then(resp => {
  }).catch(err => { })
  history.push('/')
}


function* logout({ payload }) {
  const { history } = payload;
  const deviceId = localStorage.getItem('deviceId');
  try {
    if (history) yield call(logoutAsync, {history,deviceId});
    localStorage.clear();

  } catch (error) { }
}

// export function* watchForgotPassword() {
//   yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }

// const forgotPasswordAsync = async (email) => {
//   return await auth.sendPasswordResetEmail(email)
//     .then(user => user)
//     .catch(error => error);
// }

// function* forgotPassword({ payload }) {
//   const { email } = payload.forgotUserMail;
//   try {
//     // const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
//     // if (!forgotPasswordStatus) {
//     //   yield put(forgotPasswordSuccess("success"));
//     // } else {
//     //   yield put(forgotPasswordError(forgotPasswordStatus.message));
//     // }
//   } catch (error) {
//     yield put(forgotPasswordError(error));

//   }
// }

// export function* watchResetPassword() {
//   yield takeEvery(RESET_PASSWORD, resetPassword);
// }

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
//     .then(user => user)
//     .catch(error => error);
// }

// function* resetPassword({ payload }) {
//   const { newPassword, resetPasswordCode } = payload;
//   try {
//     // const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
//     // if (!resetPasswordStatus) {
//     //   yield put(resetPasswordSuccess("success"));
//     // } else {
//     //   yield put(resetPasswordError(resetPasswordStatus.message));
//     // }
//   } catch (error) {
//     yield put(resetPasswordError(error));

//   }
// }



// DELETE ONE
const deleteOneUserRequest = async (payload) => {

  return await axiosApi.delete(`${api.users}/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* deleteOneUser({ payload }) {
  try {
    const ret = yield call(deleteOneUserRequest, payload);

    if (ret && ret.status === 200) {
      const { history, index } = payload;

      if (history) history.push('/admin/users/list')
      yield put(deleteOneUserSuccess({ index }));
    }
    else yield put(deleteOneUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneUserError({ error, message: (error) ? error.message : '' }));
  }
}
export function* watchdeleteOneUser() {
  yield takeEvery(USER_DELETE, deleteOneUser);
}




export default function* rootSaga() {
  yield all([
    fork(watchGetUsersList),
    fork(watchchangeUserState),
    fork(watchGetFilter),
    fork(watchLoginUser),
    fork(watchChatLoginUser),
    //    fork(watchEditUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    //    fork(watchForgotPassword),
    // fork(watchResetPassword),
    fork(watchGetChatroomId),
    fork(watchdeleteOneUser),
    fork(watchGetCompany)
  ]);
}