import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,

  USER_GET_BY_FILTER,
  USER_GET_BY_FILTER_SUCCESS,
  USER_GET_BY_FILTER_ERROR,


  USER_CHANGE_STATE,
  USER_CHANGE_STATE_SUCCESS,
  USER_CHANGE_STATE_ERROR,
  SELECT_USER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,

  CHAT_LOGIN_USER,
  CHAT_LOGIN_USER_SUCCESS,
  CHAT_LOGIN_USER_ERROR,
  // CHAT_LOGOUT_USER,

  GET_CHATROOM_ID,
  GET_CHATROOM_ID_SUCCESS,
  GET_CHATROOM_ID_ERROR,

  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,

  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,

  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,

  // RESET_PASSWORD,
  // RESET_PASSWORD_SUCCESS,
  // RESET_PASSWORD_ERROR,

  USER_CHANGE_BOX_VIEW,

  USER_DELETE,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,

  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR

} from './actionTypes';
import { toast } from "react-toastify";
import React from "react"
import IntlMessages from "../../helpers/IntlMessages";
// import axiosApi from "../../helpers/axiosConfig";
// import { api } from '../../constants/defaultValues';
// import { useDispatch } from 'react-redux';
import UserSrv from '../../services/UserSrv';
// GET LIST
export const getUsersList = (payload) => ({
  type: USER_GET_LIST,
  payload
});
export const getUsersListSuccess = (payload) => ({
  type: USER_GET_LIST_SUCCESS,
  payload
});
export const getUsersListError = (payload) => ({
  type: USER_GET_LIST_ERROR,
  payload
});


// GET COMPANY
export const getCompany = (payload) => ({
  type: GET_COMPANY,
  payload
});
export const getCompanySuccess = (payload) => ({
  type: GET_COMPANY_SUCCESS,
  payload
});
export const getCompanyError = (payload) => ({
  type: GET_COMPANY_ERROR,
  payload
});


//GET BY FILTER

export const getUserByFilter = (payload) => ({
  type: USER_GET_BY_FILTER,
  payload
});
export const getUserByFilterSuccess = (payload) => ({
  type: USER_GET_BY_FILTER_SUCCESS,
  payload
});
export const getUserByFilterError = (payload) => ({
  type: USER_GET_BY_FILTER_ERROR,
  payload
});


export const changeUserState = (payload) => ({
  type: USER_CHANGE_STATE,
  payload
});
export const changeUserStateSuccess = (payload) => ({
  type: USER_CHANGE_STATE_SUCCESS,
  payload
});
export const changeUserStateError = (payload) => ({
  type: USER_CHANGE_STATE_ERROR,
  payload
});


export const LoginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const LoginUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload
});
export const LoginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});




// ================= PATCH
export const editUser = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDIT_USER,
    });
    UserSrv.editUser(payload)
      .then((response) => {
        if (response && response?.data?.user) {
          const resp = response?.data; 
          localStorage.setItem('odesco_user', JSON.stringify(response?.data?.user))
          dispatch({
            type: EDIT_USER_SUCCESS,
            payload: resp,
          }, toast.success(<IntlMessages id="forms.success" />));

          callback(resp);
        }
        else {
          if (callbackError) {
            toast.error(<IntlMessages id="forms.error" />)
            callbackError({ error: response?.data.error });
          }
        }
      })
      .catch(e => {
        dispatch({
          type: EDIT_USER_ERROR,
          payload: { error: {} }
        }, toast.error(<IntlMessages id="forms.error" />));
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// ================= PATCH PASSWORD
export const editPasswordUser = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDIT_USER,
    });

    UserSrv.editPasswordUser(payload)
      .then((response) => {
        if (response && response?.data?.user) {
          const resp = {
            user: response,
          }
          localStorage.setItem('odesco_user', JSON.stringify(response?.data?.user))
          dispatch({
            type: EDIT_USER_SUCCESS,
            payload: resp,
          }, toast.success(<IntlMessages id="forms.success" />));

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EDIT_USER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =================FORGET PASSWORD
export const forgetPassword = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    UserSrv.forgetPassword(payload)
      .then((response) => {
        if (response) {
          const resp = {
            forget_password: response,
          }

          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: resp,
          }, toast.success(<IntlMessages id="forms.success" />));

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

export const verifCode = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    UserSrv.verifCode(payload)
      .then((response) => {
        if (response) {
          const resp = {
            valid_code: response,
          }

          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: resp,
          }, toast.success(<IntlMessages id="forms.success" />));

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
export const resetPassword = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    UserSrv.resetPassword(payload)
      .then((response) => {
        if (response) {
          const resp = {
            valid_code: response,
          }

          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: resp,
          }, toast.success(<IntlMessages id="forms.success" />));

          callback(resp);
        }
        else {
          console.log('resp', response)
          if (callbackError) callbackError({ error: response?.error });
        }
      })
      .catch(e => {
        dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// export const forgotPassword = (forgotUserMail, history) => ({
//   type: FORGOT_PASSWORD,
//   payload: { forgotUserMail, history }
// });
// export const forgotPasswordSuccess = (forgotUserMail) => ({
//   type: FORGOT_PASSWORD_SUCCESS,
//   payload: forgotUserMail
// });
// export const forgotPasswordError = (message) => ({
//   type: FORGOT_PASSWORD_ERROR,
//   payload: { message }
// });

// export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
//   type: RESET_PASSWORD,
//   payload: { resetPasswordCode, newPassword, history }
// });
// export const resetPasswordSuccess = (newPassword) => ({
//   type: RESET_PASSWORD_SUCCESS,
//   payload: newPassword
// });
// export const resetPasswordError = (message) => ({
//   type: RESET_PASSWORD_ERROR,
//   payload: { message }
// });



export const registerUser = (payload) => ({
  type: REGISTER_USER,
  payload
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history, token, rocketToken, rocketId) => ({
  type: LOGOUT_USER,
  payload: { history, token, rocketToken, rocketId }
});

export const chatLogin = (user, history) => ({
  type: CHAT_LOGIN_USER,
  payload: { user, history }
});
export const chatLoginUserSuccess = (user) => ({
  type: CHAT_LOGIN_USER_SUCCESS,
  payload: user
});
export const chatLoginUserError = (message) => ({
  type: CHAT_LOGIN_USER_ERROR,
  payload: { message }
});


export const getRoomId = (data, history) => ({
  type: GET_CHATROOM_ID,
  payload: { data, history }
});
export const getRoomIdSuccess = (roomId) => ({
  type: GET_CHATROOM_ID_SUCCESS,
  payload: roomId
});
export const getRoomIdError = (message) => ({
  type: GET_CHATROOM_ID_ERROR,
  payload: { message }
});



export const changeBoxView = (payload) => ({
  type: USER_CHANGE_BOX_VIEW,
  payload: payload
});

// DELET USER
export const deleteOneUser = (payload) => ({
  type: USER_DELETE,
  payload
});
export const deleteOneUserSuccess = (payload) => ({
  type: USER_DELETE_SUCCESS,
  payload
});
export const deleteOneUserError = (payload) => ({
  type: USER_DELETE_ERROR,
  payload
});


// SELECT USER
export const selectUser = (payload) => ({
  type: SELECT_USER,
  payload
});