export const NOTIFICATION_GET_LIST = "NOTIFICATION_GET_LIST";
export const NOTIFICATION_GET_LIST_SUCCESS = "NOTIFICATION_GET_LIST_SUCCESS";
export const NOTIFICATION_GET_LIST_ERROR = "NOTIFICATION_GET_LIST_ERROR";

export const NOTIFICATION_GET_ONE = "NOTIFICATION_GET_ONE";
export const NOTIFICATION_GET_ONE_SUCCESS = "NOTIFICATION_GET_ONE_SUCCESS";
export const NOTIFICATION_GET_ONE_ERROR = "NOTIFICATION_GET_ONE_ERROR";

export const PEER_SET_ONE = "PEER_SET_ONE";
export const PEER_SET_ONE_SUCCESS = "PEER_SET_ONE_SUCCESS";
export const PEER_SET_ONE_ERROR = "PEER_SET_ONE_ERROR";